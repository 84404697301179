import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "dropdownMenu" ]

    initialize() {
        this.isShowingDropdown = false
    }

    toggleDropdown() {
        if (this.isShowingDropdown) {
            this.dropdownMenuTarget.classList.remove('visible')
            this.dropdownMenuTarget.classList.add('invisible')
        } else {
            this.dropdownMenuTarget.classList.remove('invisible')
            this.dropdownMenuTarget.classList.add('visible')
        }

        this.isShowingDropdown = !this.isShowingDropdown

    }
}
