import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "settingContainer" ]

    initialize () {
        this.isShowMenu = false
    }

    toggleSetting (e) {
        e.preventDefault()
        if (this.isShowMenu) {
            this.settingContainerTarget.classList.remove('visible')
            this.settingContainerTarget.classList.add('invisible')
        } else {
            this.settingContainerTarget.classList.remove('invisible')
            this.settingContainerTarget.classList.add('visible')
        }
        this.isShowMenu = !this.isShowMenu
    }
}
