import "@hotwired/turbo-rails"
import "trix"
import "./controllers"
import 'sharer.js'

import {AttachmentUpload} from "@rails/actiontext/app/javascript/actiontext/attachment_upload"

addEventListener("trix-attachment-add", event => {
    const {attachment, target} = event

    if (attachment.file) {
        const upload = new AttachmentUpload(attachment, target)
        upload.start()
    }
})
