import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'


export default class extends Controller {
    static targets = [
        'districtSelectDom',
        'subDistrictSelectDom',
        'postalCodeInputDom',
        'tempPostalCodeInputDom'
    ]

    provinceChanged(event) {
        let params = new URLSearchParams()
        params.append("district_target", this.districtSelectDomTarget.id)
        params.append("sub_district_target", this.subDistrictSelectDomTarget.id)
        params.append("postal_code_target", this.postalCodeInputDomTarget.id)
        params.append("temp_postal_code_target", this.tempPostalCodeInputDomTarget.id)

        const provinceId = event.target.selectedOptions[0].value
        const url = `/auto_complete/province/${provinceId}?${params}`

        get(url, {
            responseKind: 'turbo-stream'
        })

    }

    districtChanged(event) {
        let params = new URLSearchParams()
        params.append("district_target", this.districtSelectDomTarget.id)
        params.append("sub_district_target", this.subDistrictSelectDomTarget.id)
        params.append("postal_code_target", this.postalCodeInputDomTarget.id)
        params.append("temp_postal_code_target", this.tempPostalCodeInputDomTarget.id)

        const districtId = event.target.selectedOptions[0].value
        const url = `/auto_complete/district/${districtId}?${params}`

        get(url, {
            responseKind: 'turbo-stream'
        })
    }


}
