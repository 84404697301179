import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ 'previewImage', 'previewImageContainer', 'submitLabel' ]

    selectedImage(event) {
        const input = event.target
        if (input.files && input.files[0]) {

            const reader = new FileReader();
            reader.onload = (event) => {
                this.previewImageTarget.src = event.currentTarget.result
            }
            reader.readAsDataURL(input.files[0])
            this.submitLabelTarget.classList.add('hidden')
            this.submitLabelTarget.closest('form').requestSubmit()
            this.previewImageContainerTarget.classList.remove('hidden')
        }
    }
}
