import { Controller } from "@hotwired/stimulus"
import Swiper, { Pagination, Autoplay } from 'swiper';

Swiper.use([Pagination, Autoplay]);


export default class extends Controller {
    connect () {
        new Swiper('.swiper', {
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            },
            autoplay: {
                delay: 5000,
            },
        });
    }
}