import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "dropdownMenu", "leftMobileMenu"]

  initialize() {
    this.isShowingDropdown = false
    this.isOpenMenu = false
  }

  toggleDropdown() {
    if (this.isShowingDropdown) {
      this.dropdownMenuTarget.classList.remove('visible')
      this.dropdownMenuTarget.classList.add('invisible')
    } else {
      this.dropdownMenuTarget.classList.remove('invisible')
      this.dropdownMenuTarget.classList.add('visible')
    }

    this.isShowingDropdown = !this.isShowingDropdown
  }

  toggleMenu() {
    if (this.isOpenMenu) {
      this.leftMobileMenuTarget.classList.remove('visible')
      this.leftMobileMenuTarget.classList.add('invisible')
    } else {
      this.leftMobileMenuTarget.classList.remove('invisible')
      this.leftMobileMenuTarget.classList.add('visible')
    }

    this.isOpenMenu = !this.isOpenMenu
  }
}
