import { Controller } from "@hotwired/stimulus"
import SimpleLightbox from "../simplelightbox";
export default class extends Controller {
    connect () {
    }

    loadSimpleLightBox () {
        new SimpleLightbox(this.element.querySelectorAll('a'), {
            overlayOpacity: 0.84
        });
    }
}